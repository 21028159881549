/**
 * Theme for STWC UK site
 */
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeMixinsLW, buildThemeWidgetStylesLW, buildThemeVarsLW } from 'dw-uk-law/theme';
import { playfairDisplayFont, ptSansFont } from '../common/nextFont';

export const buildThemeVarsSTWC = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const stwcBurgundy = '#7A0040'; //color1
  const stwcMing = '#3A7A79'; //color2
  const stwcCream = '#F6F5F2'; //color3
  const stwcLightMing = '#EBF2F2'; //color4
  //secondary
  const stwcPurple = '#1A7C7B'; //color4
  const stwcLightGreen = '#98CAA6'; //color5
  const stwcBlue = '#005C7C'; //color6
  const stwcOrange = '#F18263'; //color7
  const stwcDuckEgg = '#A8BBB7'; //color8
  const stwcSand = '#D7B796'; //color9
  const stwcMushroom = '#786C5E'; //color10
  const stwcDarkMing = '#003538'; //color10
  const stwcBodyColor = '#222222';
  const stwcSvgCheckboxBg = '%233A7A79';

  //CTA colors
  const stwcCTAMingGreen = stwcMing;
  const stwcCTARolloverMing = stwcDarkMing;
  const stwcCTABurgundy = stwcBurgundy;
  const stwcCTARolloverPink = '#6F1D3E';

  const playfairDisplay = playfairDisplayFont.style.fontFamily;
  const PTSans = ptSansFont.style.fontFamily;

  const lwThemeVars = buildThemeVarsLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'STWC UK',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo-mobile.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-dark.svg`,
    },
    colors: {
      primary1: stwcMing,
      primary2: stwcMing,
      primary3: stwcCream,
      primary5: stwcLightMing,
      secondary1: stwcPurple,
      secondary2: stwcLightGreen,
      secondary3: stwcBlue,
      secondary4: stwcOrange,
      secondary5: stwcDuckEgg,
      secondary6: stwcSand,
      secondary7: stwcMushroom,
      secondary8: stwcBodyColor,

      //specific colour definitions
      btnsPrimaryBg: stwcCTAMingGreen,
      btnsPrimaryColor: vars.colors.white,
      btnsPrimaryHoverBg: stwcCTARolloverMing,
      btnsPrimaryHoverColor: vars.colors.white,
      btnsSecondaryBg: vars.colors.transparent,
      btnsSecondaryColor: vars.colors.black,
      btnsSecondaryHoverBg: stwcCTARolloverMing,
      btnsSecondaryHoverColor: vars.colors.white,
      btnsTertiaryBg: vars.colors.transparent,
      btnsTertiaryColor: vars.colors.black,
      btnsTertiaryBorder: vars.colors.transparent,
      btnsTertiaryHoverBg: vars.colors.transparent,
      btnsTertiaryHoverColor: vars.colors.black,
      svgCheckboxBg: stwcSvgCheckboxBg,
      savingsTextColor: stwcMing,
      textPrimaryBg: stwcMing,
      bgScarcityColor: stwcCTARolloverPink,

      linkPrimaryColor: vars.colors.black,
      linkSecondaryColor: stwcCTAMingGreen,
      linkTertiaryColor: stwcCTABurgundy,

      headerCartBtn: stwcCTAMingGreen,
      headerCartBtnSmall: stwcCTAMingGreen,
      headerSignInBtnSmall: stwcCTABurgundy,

      footerPrimaryBg: stwcBurgundy,
      footerGuidelinesFillColor: stwcBurgundy,
      footerIconFillColor: vars.colors.white,
      footerdrinkaware: vars.colors.white,

      bgColors: {
        STWCBurgundy: stwcBurgundy,
        STWCTeal: stwcMing,
        STWCCream: stwcCream,
        STWCPurple: stwcPurple,
        STWCLightGreen: stwcLightGreen,
        STWCBlue: stwcBlue,
        STWCOrange: stwcOrange,
        STWCDuckEgg: stwcDuckEgg,
        STWCSand: stwcSand,
        STWCMushroom: stwcMushroom,
      },
    },
    fonts: {
      sizeH1: '30px',
      sizeH2: '25px',
      sizeH3: '20px',
      familyPrimary: playfairDisplay,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: PTSans,
      headerMainNavTitle: playfairDisplay,
      headerMainNavSubTitle: PTSans,
      weightLight: '300',
      weightNormal: '400',
      weightMedium: '500',
      weightMediumPlus: '600',
      weightBold: '700',
    },
  });
};

export const buildThemeMixinsSTWC = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const themeStyles = buildThemeMixinsLW(vars);

  return merge({}, themeStyles, {
    hxl: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize44};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize60};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize34};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize30};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize34};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize26};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize26};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize24};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing9};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightMedium};
      font-style: ${vars.fonts.styleItalic};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
  });
};

export const buildThemeWidgetStylesSTWC = (vars: Omit<Theme, 'widgets'>) => {
  const themeStyles = buildThemeWidgetStylesLW(vars);

  return merge({}, themeStyles, {
    Footer: {
      default: `
      ${themeStyles.Footer.default}
      .footer-bottom-section {
        background: ${vars.colors.footerPrimaryBg};
        box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
        clip-path: inset(0 -100vmax);
        color: ${vars.colors.white};
        .wrapper .brand img {
          height: 54px !important;
        }
      }
      .social-connections {
        background: ${vars.colors.footerPrimaryBg};
        box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
        clip-path: inset(0 -100vmax);
        .wrapper {
          .social-connection-heading p, i {
            color: ${vars.colors.white};
          }
        }
    }
    `,
      navItems: `
      ${themeStyles.Footer.navItems}
    `,
    },
    Search: {
      hitWrapper: `
      ${themeStyles.Search.hitWrapper}
      .image-container{
        .offer{
          svg path:first-of-type{
            fill:#CB5442!important;
          }
        }
      }
      `,
      wrapper: `
      ${themeStyles.Search.wrapper}
      .filter-header {
        p {
          font-weight: ${vars.fonts.weightMedium};
        }
      }
      `,
    },
    Membership: {
      membershipContainer: `
        background-color: #F6F5F2;
        margin:0;
      `,
      wrapper: `
      ${themeStyles.Membership.wrapper}
      padding-top: 15px;
      flex-direction: column;
      background-color: #F6F5F2;

      ${vars.breakpoints.md} {
        flex-direction: row;
      }

      .banner-content_container{
        & h2{
        font-family: ${vars.fonts.familyTertiary};
            font-size: ${vars.fonts.fontSize26};
            font-weight: ${vars.fonts.weightNormal};
            text-transform: unset;
        }
      }

    .card-container {
      .card-layout-wrapper {
        
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;

        ${vars.breakpoints.sm} {
          grid-template-columns: repeat(3, 1fr); 
        }

        .membership-card {
            width: auto;
            height: auto;

          .cancel-membership {
            width: 100%;

            .cancel-membership-text {
              width: 100%;
            }
          }

          .change-payment-card a {
            text-decoration: underline;

            :hover {
              text-decoration: none;
            }
          }
        }

        .faq-card {
          grid-column: 1/-1;

          ${vars.breakpoints.sm} {
            grid-column: 1/3;
            &.active{
              grid-column: 2/4;
            }
          }
        }
        
      }
    }

      .mobile-sidebar-view {
        display: flex;
        flex-direction: column;
        padding:0px;

        > .membership-sidebar {
          width: 100%;
          background: ${vars.colors.grey10};
          height: fit-content;
          padding-left: 20px;
          margin-bottom: 10px;
        }

        ${vars.breakpoints.md} {
          display: none;
        }


        > .sidebar-dropdown-text {
            display: block;
            width: 100%;
            border-color: ${vars.colors.grey20};
            color: ${vars.colors.secondary8};
            height: 40px;
            cursor: pointer;
            border: 1px solid transparent;
            border-radius: 5px;
            font-size: 14px;
            font-weight: bold;
        }
      }

      .hide-sidebar {
        display: none;
      }

      .sidebar-view {
        > .membership-sidebar {
          background: ${vars.colors.grey10};
          height: fit-content;
          margin-bottom: 10px;
          max-width: 230px;
          margin-right: 15px;
          padding-left: 12px;
        }
      }

      .sidebar {
        background-color: #F6F5F2;
      > .membership-sidebar {
        width: 100%;
        background: #FFFFFF;
        height: fit-content;
        padding-left: 20px;
        margin-bottom: 10px;
        
        ${vars.breakpoints.md} {
          max-width: 230px;
          margin-right: 15px;
          padding-left: 12px;
        }

        > img {
          width: 14px;
          height: 14px;
        }
        & .navigation-link {
            max-height: fit-content;
            > h4 {
              text-transform: uppercase;
              font-size: 16px;
              color: #333333;
              margin-top: 12px;
              margin-bottom: 15px;
              text-wrap: nowrap;
              width: fit-content;
              font-weight: bold;
              
            }
            & a{
              font-size:14px;
              color: #333333;
              margin-left: 4px;
              margin-bottom: 10px;
              text-wrap: nowrap;
              height: 24px;
              width: fit-content;
              display: flex;
              gap: 10px;
              > div {
                overflow-y: hidden;
                > img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
        }
      }
    }`,
    },
    GlobalHeader: {
      mainNavBarWrapper: `
        ${themeStyles.GlobalHeader.mainNavBarWrapper}
        .megaNavTab {
          font-size: 18px;
          text-underline-offset: ${vars.space.lg};
          padding: ${vars.space.lg} 15px;
          display: inline-block;
          font-family: ${vars.fonts.headerMainNavTitle};
          color: ${vars.colors.grey50};
          font-weight: 500;
          letter-spacing: ${vars.fonts.fontLetterSpacing5};
          ${vars.breakpoints.xl} {
            padding: ${vars.space.lg} 20px;
          }

          &:hover{
            text-decoation: none;
          }

          &.emphasize, &.emphasize span {
            color: ${vars.colors.primary1};
            font-weight: ${vars.fonts.weightBold};
          }
          &:focus{
            text-decoration: none;
          }
        }
      `,
    },
    Cart: {
      MiniCart: `
      ${themeStyles.Cart.MiniCart}

      .price-section {
        ${vars.breakpoints.md}{ 
          flex-flow: column;
          align-items: baseline;
        }
      }

      .price-section .strike,  .price-section .sale-price {
        ${vars.breakpoints.md}{ 
          font-size: 24px;
          line-height: 27.6px;
        }
      }

      .price-section .strike+.sale-price {
        color: ${vars.colors.primary1}
      }

      .gift-other-device h4, .gift-tablet h4 {
        color: ${vars.colors.secondary8};
      } 

      .auc-Recommend .mini-cart-title, .product-item-container .product-title,  .product-price span.price-cart {
        font-size:20px;
        line-height:24px;
        font-weight: ${vars.fonts.weightMedium};
        font-family: ${vars.fonts.familyPrimary};
        margin:18px 0;
        letter-spacing: -0.02em;
        ${vars.breakpoints.md} {
          font-size:24px;
          line-height:28.8px;
        }
      }

      .auc-Recommend .mini-cart-title, .product-item-container .product-title {
       color: ${vars.colors.secondary8};
      }

      .product-item-container .product-title {
        height:auto;
      }

      .review-count {
        font-size:24px;
        line-height:28.8px;
      }

      .continue-shopping-text {
        color: ${vars.colors.secondary8};
        font-family: ${vars.fonts.familySecondary};
        font-size: 12px;
        font-weight: ${vars.fonts.weightMediumPlus};
        line-height: 14px;
        letter-spacing: 0.02em;
      }
    `,
      Pill: `
        ${themeStyles.Cart.Pill}
        background-color: ${vars.colors.bgScarcityColor};
      `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsSTWC, buildThemeMixinsSTWC, buildThemeWidgetStylesSTWC);
export default createTheme;
