import { Playfair_Display, PT_Sans } from 'next/font/google';

const playfairDisplayFont = Playfair_Display({
  weight: ['500'],
  style: ['normal'],
  subsets: ['latin'],
});
const ptSansFont = PT_Sans({
  weight: ['400', '700'],
  style: ['normal'],
  subsets: ['latin'],
});

export { playfairDisplayFont, ptSansFont };
